<div class="top-header-nav-home">
  <div class="container">

    <nav *ngIf="empresa" class="navbar navbar-expand-md navbar-light header-nav-algo-coodiv mt-0 pt-0 pb-2">

      <a class="navbar-brand" href="index.html">
        <img class="black-bg-logo logo" [src]="URI_LOGOS + empresa.logoOscuro"
             alt="Logo Claro" width="125" />

        <img class="white-bg-logo logo" [src]="URI_LOGOS + empresa.logoOscuro"
             alt="Logo Oscuro"/>

      </a>

      <button class="navbar-toggle offcanvas-toggle menu-btn-span-bar ml-auto" data-toggle="offcanvas"
              data-target="#offcanvas-menu-home" (click)="mostrarOcultarMenu()">
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div class="collapse navbar-collapse navbar-offcanvas offcanvas-transform js-offcanvas-done"
           id="offcanvas-menu-home">

        <ul class="navbar-nav ml-auto">

          <app-menu-selector *ngFor="let menu of menus; i as index" [menu]="menu"
                             [color]="colorServicios"></app-menu-selector>

          <li class="nav-item">
            <a data-toggle="tooltip" data-placement="bottom" title="search" class="search-headr" href="#"><img
              src="assets/img/svgs/search.svg"
              alt=""/></a>
          </li>
        </ul>
        <a class="navbar-brand" href="https://www.transparencia.gob.pe/reportes_directos/pte_transparencia_info_finan.aspx?id_entidad=13743" Target="_blank">
          <img class="black-bg-logo logo" src="assets/img/backrounds/trans.png"/>
        </a>
      </div>

    </nav>
  </div>
</div>

<style>
  .logo{
     height:60px !important
  }
</style>
